<template>
  <div class="mine-order-page">
    <GeekQooSearch></GeekQooSearch>
    <div class="top-title">
      <p>我的订单</p>
    </div>
    <div class="order-list">
      <div class="search-box">
        <div class="item-input">
          <input
            v-model="customerName"
            placeholder="输入客户名查询"
            type="text"
            @keyup.13="onSearch()"
          />
          <van-icon name="search" @click="onSearch()" />
        </div>
      </div>
      <van-list
        v-model="order.loading"
        :finished="order.finished"
        finished-text="没有更多了"
        @load="getList"
      >
        <div v-for="(item, index) in order.list" :key="index" class="list-item">
          <div class="item-left">
            <p>
              <b>订单号：{{ item.id }}</b>
            </p>
            <p>
              订单金额：<span>￥{{ item.price }}</span> 时间：{{
                item.created_at
              }}
            </p>
          </div>
          <div class="item-right">
            <a @click="toDetail(item.id)">查看详情</a>
            <span>｜</span>
            <a @click="toCode(item.id)">二维码</a>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
// 引入mixins
import mixins_config from "@/mixins/config.js";
// 引入组件
import GeekQooSearch from "@/components/GeekQooSearch";
import { GET_ORDERS } from "@/api/dataProvider";

export default {
  name: "MineOrder",
  mixins: [mixins_config],
  components: { GeekQooSearch },
  data() {
    return {
      customerName: "",
      order: {
        current: 1,
        loading: false,
        finished: false,
        list: []
      }
    };
  },
  created() {},
  mounted() {},
  methods: {
    onSearch() {
      this.order = {
        current: 1,
        loading: false,
        finished: false,
        list: []
      };
      this.getList();
    },
    getList() {
      let that = this;
      GET_ORDERS(
        {
          customerName: that.customerName,
          page: that.order.current
        },
        function(res) {
          if (res.data.code === 0) {
            that.order.loading = false;
            if (that.order.list.length > 0 && res.data.data.current_page == 1) {
              return false;
            }
            that.order.list = that.order.list.concat(res.data.data.data);
            if (res.data.data.last_page > that.order.current) {
              that.order.current += 1;
            } else {
              that.order.finished = true;
            }
          }
        }
      );
    },
    toDetail(id) {
      window.location.href = `http://dgsc.demo.qoomoe.com/order/${id}/print`;
    },
    toCode(id) {
      this.$router.push({
        name: "Checkout",
        params: { id: id }
      });
    }
  }
};
</script>

<style lang="scss">
.mine-order-page {
  padding: 0 0 50px 0;

  .top-title {
    background: #f96719;

    p {
      line-height: 35px;
      text-align: center;
      font-size: 10px;
      color: #fff;
    }
  }

  .order-list {
    padding: 10px 20px;

    .search-box {
      width: 100%;
      border: 1px solid #e6e6e6;
      border-radius: 5px;

      .item-input {
        position: relative;
        width: 100%;
        height: 28px;

        input {
          width: 100%;
          height: 100%;
          border: none;
          background: rgba(#ffffff, 0.5);
          padding: 0 0 0 10px;
          font-size: 10px;
          color: #333333;
          border-radius: 2px;
        }

        i {
          display: block;
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          font-size: 15px;
          color: #333333;
        }
      }
    }

    .list-item {
      display: flex;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid #eeeeee;

      .item-left {
        p {
          font-size: 10px;
          color: #2d2d2d;

          &:first-child {
            margin-bottom: 5px;
          }

          span {
            margin-right: 5px;
            font-weight: bold;
            color: #f54a4a;
          }
        }
      }

      .item-right {
        margin-left: auto;

        a,
        span {
          display: inline-block;
          vertical-align: middle;
          font-size: 10px;
          font-weight: bold;
          color: #f96719;
        }
      }
    }
  }
}
</style>
